import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';
import LayoutHeaderAndFooter from '../components/layout-header-and-footer';
import { padding10, displayFlex, spaceBetween } from '../styles/class-names';
import Seo from '../components/seo';

export default function Photos({ data }: { data: any }) {
  const [currentImage, setCurrent] = React.useState(0);

  const increment = () => {
    if (currentImage === data.allFile.edges.length - 1) {
      setCurrent(0);
    } else {
      setCurrent(currentImage + 1);
    }
  }

  const decrement = () => {
    if (currentImage === 0) {
      setCurrent(data.allFile.edges.length - 1);
    } else {
      setCurrent(currentImage - 1);
    }
  }

  const image = data.site.siteMetadata.siteUrl + '/' + data.allFile.edges[currentImage].node.relativePath;

  return <LayoutHeaderAndFooter>
    <article className={padding10}>
      <Seo title="Photos" description="Here you'll find an assortment of photos shot by Michael J. Bennett" imagePath={image}/>
      <h2>Photos</h2>
      <Img fixed={data.allFile.edges[currentImage].node.childImageSharp.fixed} />
      <nav className={`${displayFlex} ${spaceBetween}`}>
      <span onClick={decrement}>&lt;</span><span onClick={increment}>&gt;</span>
      </nav>
    </article>
  </LayoutHeaderAndFooter>
}

export const query = graphql`{
  allFile(filter: {relativeDirectory: {glob: "img/photos/*", ne: "img/photos/profiles"}}) {
    edges {
      node {
        childImageSharp {
          fixed(width: 800, height:600){
            ...GatsbyImageSharpFixed
          }
        }
        name
        relativePath
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
